<template>
	<div class="newsDetail">
		<div class="newsDetail_nav">
			<the-nav>
				<svg class="svg_logo" aria-hidden="true">
					<use xlink:href="#logo-skiff-black"></use>
				</svg>
			</the-nav>
		</div>
		<div class="newsDetail_con w1200">
			<div class="newsDetail_con_back" @click="$router.go(-1)">
				<svg class="svg_back" aria-hidden="true">
					<use xlink:href="#arrowright"></use>
				</svg>
				返回
			</div>
			<article>
				<h1 class="title">
					{{ detail.title }}
				</h1>
				<p class="time">{{ detail.date }}</p>
				<div class="tags">
					<div
						class="tags_item"
						v-for="(item, index) in detail.tags"
						:key="index"
					>
						{{ item.tagName }}
					</div>
				</div>
				<div class="hr"></div>
				<div class="content" v-html="detail.content"></div>
			</article>
		</div>
		<div
			class="newsDetail_relevance w1200"
			v-show="this.newsList.length > 1"
		>
			<div class="previous">
				<p @click="previousClick">上一篇</p>
				<div class="previous_box" @click="previousClick">
					<div class="title">{{ previous.title }}</div>
					<div class="time">{{ previous.date }}</div>
					<div class="tags">
						<div
							class="tags_item"
							v-for="(item, index) in previous.tags"
							:key="index"
						>
							{{ item.tagName }}
						</div>
					</div>
				</div>
			</div>
			<div class="previous">
				<p @click="nextClick">下一篇</p>
				<div class="previous_box" @click="nextClick">
					<div class="title">{{ next.title }}</div>
					<div class="time">{{ next.date }}</div>
					<div class="tags">
						<div
							class="tags_item"
							v-for="(item, index) in next.tags"
							:key="index"
						>
							{{ item.tagName }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<the-footer></the-footer>
	</div>
</template>

<script>
	export default {
		name: "news-detail",
		data() {
			return {
				detail: {},
				newsList: []
			};
		},
		computed: {
			previous() {
				let obj = {};
				for (let i = 0; i < this.newsList.length; i++) {
					let item = this.newsList[i];
					if (item.id == this.$route.query.id) {
						obj =
							this.newsList[
								i - 1 < 0 ? this.newsList.length - 1 : i - 1
							];
						break;
					}
				}
				return obj;
			},
			next() {
				let obj = {};
				for (let i = 0; i < this.newsList.length; i++) {
					let item = this.newsList[i];
					if (item.id == this.$route.query.id) {
						obj =
							this.newsList[
								i + 1 > this.newsList.length - 1 ? 0 : i + 1
							];
						break;
					}
				}
				return obj;
			}
		},
		watch: {
			$route: {
				handler() {
					this.getInfo();
				},
				immediate: true
			}
		},
		created() {
			this.newsList = JSON.parse(localStorage.getItem("newsList") || []);
		},
		methods: {
			getInfo() {
				this.$getJSON(`/api/news/info/${this.$route.query.id}`).then(
					res => {
						this.detail = res.data || {};
						document.title =
							this.detail.title || "新闻详情-轻舟能科官网";
					}
				);
			},
			previousClick() {
				this.$router.replace({
					name: "newsDetail",
					query: { id: this.previous.id }
				});
			},
			nextClick() {
				this.$router.replace({
					name: "newsDetail",
					query: { id: this.next.id }
				});
			}
		}
	};
</script>

<style lang="scss">
	.newsDetail {
		user-select: text;
		&_nav {
			height: 80px;
			box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.15);
			.theNav {
				li,
				a,
				span {
					color: rgba(0, 0, 0, 0.65);
					&:hover {
						color: #00c699;
					}
				}
				.btn {
					background: rgba(0, 198, 153, 0.15);
					border: 1px solid #00c699;
					color: #00c699;
					&:hover {
						color: #fff;
						background-color: #00c699;
					}
				}
				&_dropdown {
					> li {
						border-right: 1px solid rgba(0, 0, 0, 0.15);
						&:hover {
							.svg_down {
								fill: #00c699;
							}
						}
					}
					&_menu {
						border: 1px solid rgba(0, 0, 0, 0.15);
					}
					.svg_down {
						fill: rgba(0, 0, 0, 0.65);
					}
				}
			}
		}
		.title {
			font-size: 40px;
			font-family: NotoSansSC, NotoSansSC-Medium;
			font-weight: 500;
			text-align: left;
			color: rgba(0, 0, 0, 0.85);
			word-break: break-all;
		}

		.time {
			margin: 16px 0 24px 0;
			font-size: 14px;
			font-family: PingFangSC, PingFangSC-Regular;
			font-weight: 400;
			text-align: left;
			color: rgba(0, 0, 0, 0.45);
		}

		.tags {
			display: flex;
			flex-wrap: wrap;
			&_item {
				padding: 1px 8px;
				margin-right: 4px;
				margin-bottom: 8px;
				border: 1px solid #d9d9d9;
				border-radius: 2px;
				font-size: 12px;
				font-family: PingFangSC, PingFangSC-Regular;
				font-weight: 400;
				text-align: left;
				color: rgba(0, 0, 0, 0.65);
			}
		}
		&_con {
			display: flex;
			padding-top: 48px;
			padding-bottom: 88px;
			&_back {
				display: flex;
				align-items: center;
				width: 120px;
				height: 64px;
				margin-right: 144px;
				font-size: 16px;
				font-family: PingFangSC, PingFangSC-Regular;
				font-weight: 400;
				text-align: left;
				color: rgba(0, 0, 0, 0.65);
				cursor: pointer;
				&:hover {
					color: #00c699;
					.svg_back {
						fill: #00c699;
					}
				}
				.svg_back {
					width: 16px;
					height: 16px;
					fill: #000000;
					transform: rotate(180deg);
					margin-right: 4px;
				}
			}
			article {
				width: 797px;

				.hr {
					width: 100%;
					height: 1px;
					background-color: #e6e6e6;
					margin: 32px auto;
				}
				.content {
					font-size: 16px;
					text-align: left;
					line-height: 2;
				}
			}
		}
		&_relevance {
			display: flex;
			justify-content: center;
			padding-bottom: 88px;

			.previous {
				> p {
					margin-bottom: 16px;
					text-align: left;
					font-size: 16px;
					font-family: PingFangSC, PingFangSC-Medium;
					font-weight: 500;
					color: rgba(0, 0, 0, 0.85);
					cursor: pointer;
				}
				&:last-child {
					margin-left: 12px;
					p {
						text-align: right;
					}
				}
				&_box {
					width: 491px;
					height: 196px;
					background: #f4f5f7;
					padding: 24px 32px;
					text-align: left;
					cursor: pointer;
					.title {
						font-size: 24px;
					}
					.time {
						margin-top: 8px;
						margin-bottom: 17px;
					}
				}
			}
		}
	}
</style>
